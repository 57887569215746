import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LoginEmpresa from './pages/LoginEmpresa';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import EmpresaProvider from './context/EmpresaProvider';
import LoginUsuario from './pages/LoginUsuario';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HomeUsuario from './pages/HomeUsuario';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Vacaciones from './pages/Vacaciones';
import Fichaje from './pages/Fichaje';
import Notificaciones from './pages/Notificaciones';
import OrientationLockWrapper from "./components/OrientationLockWrapper";

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        index: true,
        element: <EmpresaProvider><HomeUsuario /></EmpresaProvider>,
      },
      {
        path: "/login",
        element: <EmpresaProvider><LoginEmpresa /></EmpresaProvider>,
      },
      {
        path: "/login/usuario",
        element: <EmpresaProvider><LoginUsuario /></EmpresaProvider>,
      },
      {
        path: "/vacaciones",
        element: <EmpresaProvider><Vacaciones /></EmpresaProvider>,
      },
      {
        path: "/fichaje/:lat/:lon",
        element: <EmpresaProvider><Fichaje /></EmpresaProvider>,
      },
      {
        path: "/notificaciones",
        element: <EmpresaProvider><Notificaciones /></EmpresaProvider>,
      },
    ]
  },
  
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <OrientationLockWrapper>
      <RouterProvider router={router} />
    </OrientationLockWrapper>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
